import { createStore } from "vuex";

import axios from "axios";

export default createStore({
  state: {
    user: null,
    all_trans: [],
    all_accounts: [],
    all_loans: [],
    share_trans: [],
    statement: "",
    loan_requests: [],
    withdraw_requests: [],
    business_loan_types: [],
    user_businesses: [],
    dashboard: {},
    loan_top_ups: [],
    custom_modules: [],
    dividends_predictions: {},
  },

  getters: {
    isLogged: (state) => !!state.user,
    userIn: (state) => state.user,
    allAccounts: (state) => state.all_accounts,
    accountStatement: (state) => state.statement,
    allLoans: (state) => state.all_loans,
    allTrans: (state) => state.all_trans.transactions,
    shareTrans: (state) => state.share_trans,
    getLoanRequests: (state) => state.loan_requests,
    getWithdrawRequests: (state) => state.withdraw_requests,
    getBusinessLoanTypes: (state) => state.business_loan_types,
    getUserBusinesses: (state) => state.user_businesses,
    getDashboard: (state) => state.dashboard,
    getLoanTopUps: (state) => state.loan_top_ups,
    getBusinessCustomModules: (state) => {
      return state.custom_modules.length > 0
        ? state.custom_modules
        : JSON.parse(localStorage.getItem("custom_modules"));
    },
    getDividendsPredictions: (state) => state.dividends_predictions,
  },

  mutations: {
    SET_TOKEN(state, tokenData) {
      localStorage.setItem("token", tokenData.token);
      axios.defaults.headers.common.Authorization = `Token ${tokenData.token}`;
    },

    SET_USER(state, userData) {
      state.user = userData.data;
    },

    SET_TRANS(state, trans) {
      state.all_trans = trans;
    },

    SET_ACCOUNTS(state, accounts) {
      state.all_accounts = accounts.response.accounts;
    },

    SET_SHARES(state, shares) {
      state.share_trans = shares;
    },

    SET_LOANS(state, loans) {
      state.all_loans = loans.loans;
    },

    SET_STATEMENT(state, trans) {
      state.statement = trans.statement;
    },

    SET_LOAN_REQUESTS(state, loan_requests) {
      state.loan_requests = loan_requests;
    },

    SET_WITHDRAW_REQUESTS(state, withdraw_requests) {
      state.withdraw_requests = withdraw_requests;
    },

    SET_BUSINESS_LOAN_TYPES(state, business_loan_types) {
      state.business_loan_types = business_loan_types;
    },

    SET_CLIENT(state, responseData) {
      state.user = responseData.user;

      state.user_businesses = responseData.businesses;

      if (responseData?.businesses?.length === 1) {
        let custom_modules = responseData.businesses[0]?.custom_modules;

        state.custom_modules = custom_modules;

        localStorage.setItem("custom_modules", JSON.stringify(custom_modules));
      }
      // console.log(responseData);
    },

    SET_DASHBOARD(state, responseData) {
      state.dashboard = responseData;
    },

    SET_LOAN_TOP_UPS(state, responseData) {
      state.loan_top_ups = responseData;
    },

    SET_BUSINESS() {},

    SET_BUSINESS_CUSTOM_MODULES(state, custom_modules) {
      state.business_custom_modules = custom_modules;
    },

    SET_DIVIDENDS_PREDICTIONS(state, responseData) {
      state.dividends_predictions = responseData;
    },

    LOGOUT() {
      localStorage.removeItem("token");
      location.reload();
    },

    DO_NOTHING() {},
  },

  actions: {
    login({ commit, dispatch }, credentials) {
      return new Promise((resolve, reject) => {
        return axios
          .post("/login/", credentials)
          .then(({ data }) => {
            resolve({ data });
            commit("SET_TOKEN", data);
            dispatch("getUser");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    // eslint-disable-next-line no-empty-pattern
    resetPassword({}, payload) {
      return axios.post("/change-password/", payload);
    },

    getUser({ commit }) {
      return axios
        .get("/profile/")
        .then(({ data }) => {
          commit("SET_USER", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
          if (error.code == "ERR_NETWORK") {
            // commit("LOGOUT");
          }
        });
    },

    fetchTransactions({ commit }) {
      return axios
        .get("/transactions/")
        .then(({ data }) => {
          commit("SET_TRANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchDeposits({ commit }) {
      return axios
        .get("/deposits/")
        .then(({ data }) => {
          commit("SET_TRANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchWithdraws({ commit }) {
      return axios
        .get("/withdraws/")
        .then(({ data }) => {
          commit("SET_TRANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchTransfers({ commit }) {
      return axios
        .get("/transfers/")
        .then(({ data }) => {
          commit("SET_TRANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchRepayments({ commit }) {
      return axios
        .get("/repayments/")
        .then(({ data }) => {
          commit("SET_TRANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchStatement({ commit }, payload) {
      return axios
        .post("/member-statement/", payload)
        .then(({ data }) => {
          // console.log('Response', data)
          commit("SET_STATEMENT", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code === "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchLoans({ commit }, requestData) {
      return axios
        .get("v2/loans/", { params: { page: requestData?.page } })
        .then(({ data }) => {
          commit("SET_LOANS", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchLoanDetails({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .get(`v2/loans/${requestData.loan_id}`)
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((error) => {
            // console.log(error)
            reject(error);
          });
      });
    },

    fetchShares({ commit }) {
      return axios
        .get("/shares/")
        .then(({ data }) => {
          commit("SET_SHARES", data);
        })
        .catch((error) => {
          // console.log(error)
          if (error.code == "ERR_BAD_REQUEST") {
            // commit("LOGOUT");
          }
        });
    },

    fetchAccounts({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/account-balance/")
          .then((response) => {
            commit("SET_ACCOUNTS", response?.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchLoanRequests({ commit }, requestData) {
      return axios
        .get(
          `/customer-loan-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
          {
            params: {
              page: requestData.page,
            },
          }
        )
        .then(({ data }) => {
          commit("SET_LOAN_REQUESTS", data.customer_loan_requests);
        })
        .catch(() => {
          // console.log(error)
          // if (error.code == 'ERR_BAD_REQUEST') {
          //   commit('LOGOUT')
          // }
        });
    },

    fetchWithdrawRequests({ commit }, requestData) {
      return axios
        .get(
          `/customer-withdraw-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
          {
            params: {
              page: requestData.page,
            },
          }
        )
        .then(({ data }) => {
          commit("SET_WITHDRAW_REQUESTS", data.customer_withdraw_requests);
        })
        .catch(() => {
          // console.log(error)
          // if (error.code == 'ERR_BAD_REQUEST') {
          //   commit('LOGOUT')
          // }
        });
    },

    fetchLoanTopUps({ commit }, requestData) {
      return axios
        .get(
          `/customer-loan-top-up-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
          {
            params: {
              page: requestData.page,
            },
          }
        )
        .then(({ data }) => {
          commit("SET_LOAN_TOP_UPS", data.customer_loan_top_up_requests);
        })
        .catch(() => {
          // console.log(error)
          // if (error.code == 'ERR_BAD_REQUEST') {
          //   commit('LOGOUT')
          // }
        });
    },

    fetchBusinessLoanTypes({ commit }) {
      return axios
        .get(`/business-loan-types/${this.state?.user?.business?.id}/`)
        .then(({ data }) => {
          commit("SET_BUSINESS_LOAN_TYPES", data.business_loan_types);
        })
        .catch(() => {
          // console.log(error)
          // if (error.code == 'ERR_BAD_REQUEST') {
          //   commit('LOGOUT')
          // }
        });
    },

    fetchLoanRequestGuarantor({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/customer-loan-request-guarantor/${this.state?.user?.business?.id}/`,
            {
              params: {
                name: requestData,
                exclude_email: this.state?.user?.user?.email,
              },
            }
          )
          .then((response) => {
            commit("DO_NOTHING");

            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    requestNewLoan({ commit /*dispatch*/ }, requestData, requestContentType) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/customer-loan-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
            requestData,
            {
              headers: {
                "Content-Type": requestContentType,
              },
            }
          )
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    requestLoanTopUp({ commit /*dispatch*/ }, requestData, requestContentType) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/customer-loan-top-up-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
            requestData,
            {
              headers: {
                "Content-Type": requestContentType ?? "application/json",
              },
            }
          )
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    requestNewWithdrawal({ commit /*dispatch*/ }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `/customer-withdraw-requests/${this.state?.user?.member_detail?.id}/${this.state?.user?.business?.id}/`,
            requestData
          )
          .then((/*{ data }*/) => {
            commit("DO_NOTHING");
            resolve(resolve);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchLoanRequest({ commit /*dispatch*/ }, loanRequestID) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer-loan-request/${loanRequestID}/`)
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    updateLoanRequest({ commit /*dispatch*/ }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .patch(
            `/customer-loan-request/${requestData?.oldData?.id}/`,
            requestData?.updateData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    deleteLoanRequest({ commit /*dispatch*/ }, loanRequestID) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/customer-loan-request/${loanRequestID}/`)
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    clientLogin({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post("/client-login/", credentials)
          .then(({ data }) => {
            commit("SET_TOKEN", data);

            commit("SET_CLIENT", data);

            resolve({ data });
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchClient({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .get("/profile/", {
            params: {
              business_number: requestData?.businessNumber,
            },
          })
          .then((response) => {
            resolve(response);

            commit("SET_USER", response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    fetchClientDashboard({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/client-dashboard/", {})
          .then((response) => {
            resolve(response);

            commit("SET_DASHBOARD", response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    forgotClientPassword({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .get("/forgot-password/", { params: { email: requestData?.email } })
          .then((response) => {
            resolve(response);

            commit("DO_NOTHING");
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    resetClientPassword({ commit }, requestData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/reset-password/", requestData, {
            params: {},
          })
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    downloadTransactions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/export-member-transactions/", {
            responseType: "arraybuffer",
          })
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    downloadStatement({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/export-member-statement/", payload, {
            responseType: "arraybuffer",
          })
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchBusinessCustomModules({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v2/business-custom-modules/", {
            params: {
              business_name: payload?.business_name,
              business_number: payload?.business_number,
            },
          })
          .then((response) => {
            commit("SET_BUSINESS_CUSTOM_MODULES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    uploadLoanApplication({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/v2/loan-application-uploads/", payload, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    downloadLoanApplicationTemplate({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get("/v2/loan-application-upload-template/", {
            params: { business: payload?.business_id },
          })
          .then((response) => {
            commit("DO_NOTHING");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchDividendsPredictions({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/v2/dividends-predictions/?business=${payload.business}`)
          .then((response) => {
            resolve(response);

            commit("SET_DIVIDENDS_PREDICTIONS", response.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },

    SignOut() {
      return axios
        .post("/logout/")
        .then(() => {
          localStorage.removeItem("token");
          location.reload();
        })
        .catch(() => {
          // console.log(error)
          localStorage.removeItem("token");
          location.reload();
        });
    },
  },

  modules: {},
});
